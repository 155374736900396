.NameInputBox {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: left;
  margin-left: 30px;
}

.InputBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
}

.Label {
  font-weight: bold;
}

.Input {
  border-radius: 10px;
  border-width: 1px 1px;
  border-style: solid;
  padding: 5px 5px;
}

.SubmitButton {
  margin-top: 20px;
  margin-left: 30px;
  border-radius: 10px;
  border-width: 1px 1px;
  border-style: solid;
}

.SubmitButton:hover {
  background-color: rgb(137, 207, 240);
  color: white;
}
