.NavLinkSet {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.NavLinks {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NavLinks li {
  margin: 1rem;
  font-size: 16px;
}

.NavLinks a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.NavLinks a:hover,
.NavLinks a:active,
.NavLinks a.active {
  background: #f8df00;
  border-color: #292929;
  color: #292929;
}

.NavLinks button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.NavLinks button:focus {
  outline: none;
}

.NavLinks button:hover,
.NavLinks button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .NavLinks {
    flex-direction: row;
  }

  .NavLinks li {
    margin: 0 0.5rem;
  }

  .NavLinks a {
    color: black;
    text-decoration: none;
  }

  .NavLinks button {
    border: 1px solid white;
    color: #292929;
    background: transparent;
  }

  .NavLinks button:hover,
  .NavLinks button:active {
    background: #f8df00;
    color: #292929;
  }

  .UtilityButton:hover {
    cursor: pointer;
  }
}
