.MainHeader {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 2;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
