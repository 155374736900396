.Container {
  width: 1200px;
  height: 100vh;
  margin: auto;
  display: block;
}

.SubjectsTab {
  width: 20%;
  float: left;
  padding: 10px;
  padding-top: 100px;
}

.SubjectTypeBox {
  margin-bottom: 20px;
}

.SubjectTypeBox h4:hover {
  color: gray;
  cursor: pointer;
}

.Subject {
  margin-bottom: 10px;
}

.Subject:hover {
  color: gray;
  cursor: pointer;
}

.MainContainer {
  display: block;
}

.SearchLine {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
}

.SearchBar {
  width: 50%;
  padding: 5px;
  margin: 30px 0;
  margin-left: 10px;
}

.SearchButton {
  margin: 30px 0;
  margin-left: 5px;
}

.CourseContainer {
  width: 80%;
  float: left;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
