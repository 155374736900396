@media (min-width: 333px) {
  .Container {
    width: 100vw;
    height: auto;
  }

  .DashboardContents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .LeftContents {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 0;
  }

  .UserOverview {
    height: auto;
    background-color: rgb(242, 242, 242);
    display: flex;
    flex-direction: column;

    border-radius: 15px;
    margin: 10px 0;
    padding: 10px 30px;
  }

  .UserIcon {
    width: 100px;
    height: 100px;
    object-fit: over;
    border-radius: 50%;
  }

  .UserProgress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 20px;
    margin: 10px 0 10px;
    background-color: white;
    font-size: 13px;
  }

  .StatBox {
    display: flex;
    flex-direction: column;
  }

  .Stat {
    font-size: 20px;
    font-weight: bold;
  }

  .Credits {
    height: auto;
    background-color: rgb(242, 242, 242);

    border-radius: 15px;
    margin: 0px 0 10px;
    padding: 10px 30px;
  }

  .CreditsBox {
    background-color: white;
    padding: 30px 20px;
    margin: 10px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 13px;
  }

  .ButtonMoreCredit {
    border-radius: 20px;
    border-style: none;
    background-color: yellow;

    font-weight: bold;
    font-size: 16px;

    padding: 10px 15px;
  }

  .RightContents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: auto;
  }

  .GridBox {
    display: flex;
    flex-direction: column;

    border-radius: 15px;
  }

  .UserItem {
    display: block;
    background-color: rgb(242, 242, 242);
    width: 100%;
    height: auto;
    border-radius: 15px;
    margin: 5px 0;
  }
  .UserItem:hover {
    cursor: pointer;
  }
  .ItemBox {
    background-color: white;
    padding: 30px 20px;
    margin: 20px 30px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 70px;
    font-size: 16px;
  }
  .Icon {
    font-size: 80px;
  }

  .Upcoming {
    background-color: rgb(242, 242, 242);
    border-radius: 15px;
    margin: 10px 0;
  }
  .UpcomingBox {
    background-color: white;
    padding: 10px 20px;
    margin: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 70px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .DashboardContents {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 700px;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .LeftContents {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 80vh;
    margin: 0;
  }
  .RightContents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 0;
    width: 100%;
    height: 80vh;
  }

  .GridBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    border-radius: 15px;
    height: 60%;
    width: 100%;
  }

  .UserItem {
    display: block;
    background-color: rgb(242, 242, 242);
    width: 160px;
    height: 180px;
    border-radius: 15px;
    margin: 5px 5px;
  }
  .UserItem:hover {
    cursor: pointer;
  }
  .ItemBox {
    background-color: white;
    /* padding: 10px 10px;
    margin: 20px 20px; */
    height: 80%;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 0;

    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    font-size: 15px;
  }
  .Icon {
    font-size: 60px;
  }
  .ItemTextBox {
    height: 100px;
  }
  .Upcoming {
    background-color: rgb(242, 242, 242);
    border-radius: 15px;
    margin: 0 5px;
    width: 100%;
  }

  .ProfileLink:hover {
    cursor: pointer;
    color: rgb(137, 207, 240);
  }
}
