/* .MainContainer {
  width: 100vw;
  height: auto;
  align-items: center;
}
.TextContainer {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
}

.TutorContainer {
  width: 70%;
  height: 55%;
  margin-left: 15%;
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TutorBox {
  background-color: white;
  border-color: rgb(240, 240, 240);
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  width: 24%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.TutorBox:hover {
  top: -3px;
  box-shadow: 1px 1px grey;
}

.MoreTutorsButton {
  width: 16%;
  margin-left: 42%;
  margin-top: 2%;
  padding: 3px;
  background-color: yellow;
  color: black;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 15px;
} */

@media (min-width: 333px) {
  .MainContainer {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .TextContainer {
    width: 80%;
    height: 10%;

    margin: 5% auto 0;
    text-align: center;
  }

  .TutorContainer {
    width: 87%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto 2%;
  }

  .TutorBox {
    background-color: white;
    border-color: rgb(240, 240, 240);
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    /* width: 200px; */
    width: 46%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2% 2%;

    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }

  .TutorBox:hover {
    top: -3px;
    box-shadow: 1px 1px grey;
  }

  .MoreTutorsButton {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    padding: 3px;
    background-color: yellow;
    color: black;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 15px;
  }
}

@media (min-width: 768px) {
  .TextContainer {
    width: 650px;
    height: 10%;
    margin: 5% auto 0;
    text-align: center;
  }

  .TutorContainer {
    width: 87%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto 2%;
  }

  .TutorBox {
    background-color: white;
    border-color: rgb(240, 240, 240);
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1% 1%;

    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
}

@media (min-width: 1100px) {
  .TutorContainer {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: center;
    margin: 20px auto 2%;
  }

  .TutorBox {
    background-color: white;
    border-color: rgb(240, 240, 240);
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    width: 225px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1% 1%;

    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
}
