.SettingBox {
  width: 300px;
  height: 200px;
  border-color: rgb(105, 105, 105, 0.1);
  border-width: 3px;
  border-style: solid;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 8px 8px;
  padding: 20px 20px;
}

.TitleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.DescriptionText {
  color: rgb(105, 105, 105, 0.8);
}
