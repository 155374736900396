.Container {
  width: 100vw;
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 5%;
}

.TypesContainer {
  width: 100%;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
