.ProfileBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
}

.InfoBox {
  display: block;
  flex-direction: column;
  width: 70%;
  height: 100%;
  padding: 10px 10px;
  padding-right: 15px;
  font-size: 0.6rem;
}

.Name {
  font-size: 1rem;
}

.Popularity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CountryIcon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.PictureBox {
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
}

.Picture {
  width: 100%;
  height: 70%;
  padding: 10px;
  object-fit: cover;
  border-radius: 50%;
}

.Description {
  margin: 10px;
  font-size: 0.7rem;
}

.SeeMore {
  display: inline;
  text-decoration: underline;
  font-weight: bold;
}

.Highlights {
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 10px;
}

.HighlightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
}

.HighlightItem {
  background-color: rgb(250, 243, 233);
  font-size: 0.7rem;
  width: 90%;
  border-radius: 5px;
  border-color: rgb(235, 229, 220);
  border-style: solid;
  border-width: 1px;
}

.DetailButton {
  background-color: black;
  color: white;
  width: 90%;
  margin-top: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
  border-radius: 15px;
  border: none;
  font-size: 14px;
}

.DetailButton:hover {
  opacity: 0.9;
}
