.InputBox {
  display: block;
  width: 100%;
}

.Label {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.Input {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-color: rgb(235, 229, 220);
  border-width: 1px;
  border-radius: 5px;
  height: 26px;
  font-size: 12px;
  padding-left: 10px;
}
