.Navigation__menu_btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.Navigation__menu_btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: Black;
}

.Logo {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Logo :hover {
  cursor: pointer;
}

.Navigation__title {
  color: Black;
  margin-right: 2rem;
}

.Navigation__title a {
  text-decoration: none;
  color: Black;
}

.Navigation__header_nav {
  display: none;
}

.Navigation__drawer_nav {
  height: 100%;
}

@media (min-width: 768px) {
  .Navigation__menu_btn {
    display: none;
  }

  .Navigation__header_nav {
    display: block;
    width: 95%;
    margin: 1%;
  }
}
